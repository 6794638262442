<template>
    <div class="m-wrapper10">
		<div class="detail-tit">
			你可以再次预约该医生
		</div>
		<div class="detail-cen">
			<div class="detail-cen-tit">
				<p class="detail-p">预约信息</p>
				<p v-if="status == 3" class="detail-p-1">已退号</p>
				<p v-if="status == 1" class="detail-p-1">已预约</p>
				<p v-if="status == 2" class="detail-p-1">已就诊</p>
			</div>
			<div class="detail-cen-box">
				<div class="detail-n">
					<p>预约医院</p>
					<em>{{recordDetail.hospitalName}}</em>
				</div>
				<div class="detail-n">
					<p>预约科室</p>
					<em>{{recordDetail.department}}</em>
				</div>
				<div class="detail-n">
					<p>预约医生</p>
					<em>{{recordDetail.doctor}}</em>
				</div>
				<div class="detail-n">
					<p>就诊日期</p>
					<em>{{recordDetail.date}}</em>
				</div>
				<div class="detail-n">
					<p>候诊时间</p>
					<em>{{recordDetail.time}}</em>
				</div>
				<div class="detail-n">
					<p>候诊序号</p>
					<em>{{recordDetail.number}}</em>
				</div>
				<div class="detail-n">
					<p>就诊卡</p>
					<em>{{recordDetail.card}}</em>
				</div>
			</div>
		</div>
		<div class="detail-cen">
			<div class="detail-cen-tit">
				<p class="detail-p">就诊人信息</p>
			</div>
			<div class="detail-cen-box">
				<div class="detail-n">
					<p>就诊人</p>
					<em>{{recordDetail.name}}</em>
				</div>
				<div class="detail-n">
					<p>证件号</p>
					<em>{{recordDetail.cardId}}</em>
				</div>
				<div class="detail-n">
					<p>手机号码</p>
					<em>{{recordDetail.phoneNumber}}</em>
				</div>
			</div>
		</div>
		<div class="detail-tibox">
			<p>订单编号：{{recordDetail.orderNumber}}</p>
			<p>创建时间：{{recordDetail.createTime}}</p>
		</div>
		<div @click="againRecord" class="detail-fot">
			<a class="m-site-but br-but">再次预约</a>
		</div>
	</div>
</template>
<script>
// import { mapState } from 'vuex'
export default {
    data() {
        return {
			status: '',
			id: '',
			recordDetail: {}
        }
    },
	mounted() {
		this.status = this.$route.params['status']
		this.id = this.$route.query['id']
		// 根据此处得到的id进一步获取详细的预约信息
		this.$store.dispatch('getRecordDetail').then(() => {
			this.recordDetail = this.$store.state.appointmentInfo.recordDetail
		})
	},
    methods: {
        againRecord() {
            // 再次预约跳转到预约选择界面
            this.$router.push('/appointment')
        }
    }
}
</script>
<style lang="scss" scoped>
.detail-tit, .detail-cen-box {
    text-align: left;
}
</style>